<template>
  <div id="AI">

    <div class="chatRoom" ref="scrollArea">
      <div class="headerImgBox">
        <img v-if="bodyPart==='头部'" src="@/assets/AI/header.png" alt="">
        <img v-if="bodyPart==='肺部'" src="@/assets/AI/lung.png" alt="">
        <img v-if="bodyPart==='肋骨'" src="@/assets/AI/rib.png" alt="">
        <img v-if="bodyPart==='胰腺'" src="@/assets/AI/pancreas.png" alt="">
        <img v-if="bodyPart==='腰椎'" src="@/assets/AI/spine.png" alt="">
        <!--      <img :style="`top:${headerParame.y}px;left:${headerParame.x}px`" src="../assets/AI/body.png" alt="">-->
        <!--      <div class="label_1" :style="`top:${headerParame.labelY};left:${headerParame.labelX}px`"></div>-->
      </div>
      <p class="disclaimer">免责说明：智能解读不能作为诊断意见，具体情况请咨询专业医生。</p>
      <div style="padding-bottom: 90px">
        <div v-for="(item,index) in chattingRecords" :key="index">
          <div class="AI_message" v-if="item.role === 'assistant'">
            <div class="AI_HerderBox">
              <img class="headerImg" src="../assets/AI/AI.png" alt="">
            </div>
            <div class="message mg">
              <div v-html="item.content"></div>
              <!--              <div class="messageButton" >-->
              <!--                <span v-on:click="evaluate(1)">很棒</span>-->
              <!--                <span v-on:click="evaluate(2,index)">重新回答</span>-->
              <!--              </div>-->
              <div v-if="index==0" class="presuppositionProblem">
                <p v-on:click="send('请帮我解读一下。')"><span>1</span>请帮我解读一下。</p>
                <p v-on:click="send('有没有什么建议。')"><span>2</span>有没有什么建议。</p>
              </div>
            </div>

          </div>
          <div class="user_message" v-if="item.role === 'user'&&!item.show">
            <div class="userHerderBox">
              <img class="headerImg" src="../assets/AI/user.png" alt="">
            </div>
            <div v-html="item.content" class="message"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="inputBox">
      <input type="text" class="sendInput" placeholder="请输入你要提问的问题" v-model="input_t">
      <img src="../assets/AI/send.png" alt="" v-on:click="send(undefined)">
      <p v-if="isShutUp" class="stopButton" v-on:click="stop">停止回答</p>
    </div>
    <div id="waterMark"></div>
  </div>
</template>

<script setup>
import {marked} from 'marked'
import {nextTick, onMounted, ref} from "vue";
import {watermark} from 'watermark-dom'
// import { useRoute } from 'vue-router'
import Queue from "@/utils/queue";

const input_t = ref("");
const scrollArea = ref(null);
const isShutUp = ref(false)
// const headerParame = ref(
//     {
//       x: 'clac(50%)',
//       y: -62,
//       labelY: "calc(50% - 40px)",
//       labelX: "calc(50%)",
//     }
// )
const chattingRecords = ref([
  // {"role":"user","content":"给我渲染一个列表"}, // 用户发出的
  // {"role":"assistant","content":"您的报告中含有【】"} //AI返回的
])
// ↓ 新增一个AI的气泡
let cacheText = ""
let isStop = ref(false)
const textType = (jsonData, lastChattingRecords,) => {
  return new Promise((resolve) => {
    if (!lastChattingRecords || lastChattingRecords.role !== "assistant") {
      lastChattingRecords = {role: "assistant", content: ""};
      chattingRecords.value.push(lastChattingRecords);
    }


    let index = 0;
    const typeText = () => {
      if (jsonData.is_end) {
        isShutUp.value = false;
        isStop.value = false
      }
      if (index < jsonData.text.length) {
        cacheText += jsonData.text[index];
        lastChattingRecords.content = marked(cacheText)
        index++;
        downRoll()
        setTimeout(typeText, 10);
      } else {
        resolve();
      }
    };

    // ↓ ------- 开始渲染字符 ---------
    typeText();
  });
};
// ↓ ------------ 滚到底部 --------------
const downRoll = () => {
  const element = document.querySelector(".chatRoom")
  setTimeout(() => {
    nextTick(element.scrollTop = element.scrollHeight) // ←AI说话往下滚
  }, 6)
}

let stopHistory = []

// 创建队列实例
const queue = new Queue();

async function processWebSocketData(data) {
  try {
    let jsonData = JSON.parse(data);

    let lastChattingRecords = chattingRecords.value[chattingRecords.value.length - 1];
    // ↓ ----------- 加入队列 进行队列调用 以免顺序出错 ------------
    await queue.enqueue(() => textType(jsonData, lastChattingRecords));
    // await
  } catch (error) {
    console.error('Error processing WebSocket data:', error);
  }
}

// 修改WebSocket的onmessage事件处理函数
let theWebSocket = new WebSocket('ws://jarvers.top:8000/ws/dialogue/');
let typingQueue = Promise.resolve(); // 创建一个已经解析的Promise作为队列的初始值

theWebSocket.onmessage = (event) => {
  setTimeout(() => {


    if (isStop.value === true) {
      let assistantRecords = chattingRecords.value.filter(record => record.role === 'assistant');

      let len = assistantRecords.length
      if (stopHistory.indexOf(len) === -1) {
        stopHistory.push(len - 1)
      }
      isStop.value = false
      isShutUp.value = false
      return
    }
    let messageIndex = JSON.parse(event.data).index
    console.log(messageIndex, "后台下标");
    if (stopHistory.indexOf(messageIndex) !== -1) {
      return;
    }

    typingQueue = typingQueue.then(() => {
      processWebSocketData(event.data)
    });
  }, 0)

};
let bodyPart = ref("")
onMounted(() => {
  const queryString = window.location.search;
  const params = new URLSearchParams(queryString.substring(1));
  const queryParams = {};
  for (const [key, value] of params) {
    queryParams[key] = value;
  }
  bodyPart.value = queryParams.bodyPart

  let aimessage = {
    "role": "assistant",
    "content": `您的报告中含有【${bodyPart.value}】检查报告为【${queryParams.imageView}】。`,
  }
  chattingRecords.value.push(aimessage)
  // setTimeout(() => {
  // let MRD = queryParams.imageView

  // theWebSocket.send("来段开场白。字数短一点 开场白的格式是：你的病例描述为“【" + MRD + "】”需要我帮助你。让你的语句正常点，不要像是回答我也不要出现开场白字样。")
  // }, 1000)

  nextTick(() => {
    var time = new Date().toLocaleString()
    console.log(watermark.init);

    watermark.init({
      watermark_txt: "影像数据中心 " + time,
      watermark_color: 'gray',
      watermark_fontsize: '12px',
      watermark_x_space: -80,
      // watermark_y_space:30,
      watermark_width: 260,     //水印宽度
      watermark_height: 20,     //水印宽度
      // // watermark_x: -30,          //水印起始位置x轴坐标
      // // watermark_y: -80,          //水印起始位置Y轴坐标
      // watermark_angle: 15,      //水印倾斜度数
      watermark_parent_node: "waterMark",

    })
  })
})

// ↓ ---------- 触发急停按钮 -----------
const stop = () => {
  isStop.value = true

}
const send = (cont) => {
  // var eventSource = new EventSource('django/chatStream/');
  // eventSource.onmessage = function(event) {
  //   console.log('来了一条奇怪的数据: ', event);
  // };
  // eventSource.onopen = function(event) {
  //   console.log('打开了一个长连接!');
  // };
  // eventSource.onerror = function(event) {
  //   if (eventSource.readyState === EventSource.CLOSED) {
  //     // 连接关闭，可能是因为网络问题或服务器关闭连接
  //     console.log('长链接 嗝屁了!');
  //   }
  // };
  // AI说话 不许插嘴
  const content = cont ? cont : input_t.value
  console.log(content);
  if (isShutUp.value) {
    return;
  }
  // 发空字符 也驳回
  if (input_t.value.length === 0 && !content) {
    return;
  }
  chattingRecords.value.push(
      {"role": "user", "content": content, "show": !!cont}
  )
  cacheText = ""
  theWebSocket.send(JSON.stringify(chattingRecords.value))
  input_t.value = ""
  isShutUp.value = true
  downRoll()
}
const evaluate = (type, content_index) => {
  if (isShutUp.value) {
    return
  }
  // let lastChattingRecords = {role: "user", content: ""};
  // chattingRecords.value.push(lastChattingRecords);
  switch (type) {
    case 1:
      // console.log("很棒");
      // theWebSocket.send('{role: "user", content: "你所有问题回答的很好"}')
      send("你所有问题回答的很好")
      break
    case 2:
      chattingRecords.value
      for (let i = content_index; i > -1; i--) {
        if (chattingRecords.value[i].role === "user") {
          console.log(chattingRecords.value[i]);
          // theWebSocket.send(`我对你回答${chattingRecords.value[i].content}的问题很不满意，请重新回答一个新的结果。`)
          send(`我对你回答${chattingRecords.value[i].content}的问题很不满意，请重新回答一个新的结果。`)
          isShutUp.value = true
          break
        }
      }
      break
  }
}

</script>
<style scoped lang="less">

#AI .chatRoom {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

#AI .chatRoom::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Opera */
}

#AI {
  max-height: 100%;
  height: 100%;
  background: #F2F4F5;

  h3 {
    margin: 40px 0 0;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  li {
    display: inline-block;
    margin: 0 10px;
  }

  a {
    color: #42b983;
  }


  html {

  }

  body {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .line {
    border-right: 2px solid #eee;
    font-size: 28px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    color: #5ec4ff;
    background: linear-gradient(0deg, #5ec4ff 0%, #ffffff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .animation {
    animation: grow 4s steps(44) 1s normal both,
    blink 0.5s steps(44) infinite normal;
  }

  @keyframes grow {
    from {
      width: 0;
    }

    to {
      width: 400px;
    }
  }

  @keyframes blink {
    from {
      border-right-color: #eee;
    }

    to {
      border-right-color: #222;
    }
  }

  .headerImgBox {
    //background: url("@/assets/AI/bg.png") no-repeat;
    //background-size: 100% auto;
    width: 100%;
    //height: 327px;
    //overflow: hidden;
    //position: relative;
    text-align: center;
    background-color: #000711;

    img {
      //position: absolute;
      //left: calc(50% - 141px);
      width: 100%;
    }

    .label_1 {
      position: absolute;
      left: calc(50% - 60px);
      top: 124.94px;
      width: 124.2px;
      height: 69.71px;
      transform: rotate(-0.57deg);
      border-radius: 100px;
      opacity: 1;
      background: linear-gradient(180deg, rgba(255, 148, 6, 0.9) 0%, rgba(254, 238, 97, 0.9) 100%);
      filter: blur(31px);
      animation-name: blinking; /* 使用定义的动画 */
      animation-duration: 3s; /* 动画持续时间，单位秒 */
      animation-iteration-count: infinite; /* 动画无限循环 */
      animation-timing-function: ease-in-out; /* 动画速度曲线 */
    }

    @keyframes blinking {
      0%, 100% { /* 开始和结束时完全不透明 */
        opacity: 1;
      }
      50% { /* 中间点时变为半透明 */
        opacity: 0.5;
      }
    }
  }

  .disclaimer {
    height: 17px;
    opacity: 1;
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    font-feature-settings: "kern" on;
    color: #3F84FB;
  }

  .chatRoom {
    height: 100%;
    overflow-y: auto;
    //padding-bottom: 10px;
    width: 100%;
  }

  :deep(.AI_message) {
    margin-top: 20px;
    display: flex;
    width: calc(100% - 22px);
    margin-left: auto;
    margin-right: auto;

    p {
      margin: 0;
    }

    .AI_HerderBox {
      border-radius: 2rem;
      width: 2rem;
      height: 2rem;
      text-align: center;
      margin-right: 12px;

      .headerImg {
        width: 2rem;
        height: 2rem;

      }
    }

    .message {
      max-width: calc(100% - 2rem - 80px);
      opacity: 1;
      font-size: 12px;
      background: #fff;
      border-radius: 0 10px 10px 10px;
      color: #333;
      line-height: 26px;
      padding: 8px 12px;
    }
  }

  :deep(.user_message) {
    margin-top: 20px;
    display: flex;
    width: calc(100% - 22px);
    margin-left: auto;
    margin-right: auto;
    flex-direction: row-reverse;

    p {
      margin: 0;
    }

    .userHerderBox {
      border-radius: 2rem;
      width: 2rem;
      height: 2rem;
      background: #fff3c4;
      margin-left: 12px;

      .headerImg {
        width: 2rem;
        height: 2rem;
      }
    }

    .message {
      max-width: calc(100% - 2rem - 80px);
      opacity: 1;
      font-size: 12px;
      background: #3F84FB;
      border-radius: 10px 0 10px 10px;
      color: #fff;
      line-height: 26px;
      padding: 8px 12px;
      display: flex;
      align-items: center;
    }
  }

  .inputBox {
    position: absolute;
    bottom: 18px;
    width: 100%;

    .sendInput {

      width: calc(100% - 38px);
      height: 44px;
      border-radius: 10px;
      opacity: 1;
      background: #FFFFFF;
      box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.06);
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      box-sizing: border-box;
      outline: none;
      border: none;
      margin-left: 19px;
      margin-right: 19px;
      padding: 10px;
      padding-right: 60px;
    }

    img {
      position: absolute;
      right: 40px;
      top: 10px;

    }
  }

  .message {
    align-content: center;
  }

  :deep(table) {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 1em;
  }


  :deep(td, th) {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }

  :deep(th) {
    background-color: #f2f2f2;
    font-weight: bold;
  }

  :deep(tr:nth-child(even)) {
    background-color: #f2f2f2;
  }

  :deep(tr:hover) {
    background-color: #ddd;
  }

  .stopButton {
    position: absolute;
    left: calc(50% - 42px);
    top: -50px;
    width: 84px;
    height: 22px;
    border-radius: 2px;
    opacity: 1;
    background: #3F84FB;
    text-align: center;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;

  }

  .messageButton {
    text-align: right;

    :nth-child(1) {
      color: #3F84FB;
    }
  ;

    :nth-child(2) {
      color: red;
    }
  }

  .presuppositionProblem {
    color: #3F84FB;
    //display: flex;
    //align-items: center;
    margin-top: 5px;
    //justify-content: center;

    span {
      width: 14px;
      height: 14px;
      border-radius: 10px;
      display: inline-block;
      background-color: #3F84FB;
      color: #fff;
      text-align: center;
      line-height: 14px;
      margin-right: 5px;
    }

    p {
      line-height: 26px;
      display: flex;
      align-items: center;
    }
  }

}

#waterMark {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  pointer-events: none;
}

</style>
