<template>
  <HelloWorld msg="Welcome to Your Vue.js App"/>
</template>

<script>
import HelloWorld from './page/AI_ChatRoom.vue'
export default {
  name: 'App',
  components: {
    HelloWorld
  },mounted() {


  }
}
</script>

<style>
#app {
  height: calc(100%);
}
body{
  padding: 0;
  margin: 0;
  height: 100%;

}
html{
  height: 100%;
  width: 100%;
}

::-webkit-scrollbar {
  display: none;
}
</style>
