class Queue {
    constructor() {
        this.queue = Promise.resolve(); // 初始化队列为一个已解决的Promise
        this.taskQueue = []; // 存储待处理的任务
    }

    // 将任务添加到队列中
    enqueue(task) {
        // 使用Promise链来确保任务按顺序执行
        this.queue = this.queue.then(() => new Promise(resolve => {
            // 将任务推送到任务队列中
            this.taskQueue.push({ task, resolve });

            // 如果队列不为空，并且当前没有正在执行的任务，则开始执行队列中的第一个任务
            if (this.taskQueue.length === 1) {
                this.dequeue();
            }
        }));
    }

    // 执行队列中的第一个任务
    dequeue() {
        // 取出队列中的第一个任务
        const { task, resolve } = this.taskQueue.shift();

        // 执行任务，并在完成后解决Promise
        task().then(() => {
            resolve(); // 标记当前任务已完成

            // 如果还有任务在队列中，则继续执行下一个任务
            if (this.taskQueue.length > 0) {
                this.dequeue();
            }
        }).catch(error => {
            // 如果任务出错，你可以选择记录错误、重新尝试或其他逻辑
            console.error('Task failed:', error);
            resolve(); // 即使出错也标记为完成，以便继续执行后续任务（或者根据需求处理）
        });
    }
}


export default Queue